<script lang="ts" setup>
const { getLead, setIsInfo, setIsOpen, setIsSchedule } = useRightSidebarStore()
function openLeadInfoSidebar(id: string) {
  getLead(id)
  setIsInfo(true)
  setIsOpen(true)
  // setIsSchedule(false)
}

const display = useDisplay()
const height = computed(() =>
  display.height.value - 104 - 52 - 32 - 110 - 16 - 8,
)

const itemHeight = ref(48)
const offsetItems = ref(2)

const virtualItems = computed(() => Number.parseInt(`${height.value / itemHeight.value}`) + offsetItems.value)

const query = computed(() => [
  Query.orderDesc('bookedAt'),
  Query.limit(virtualItems.value),
  Query.equal('state', ['gebucht']),
  Query.select(['$id', 'bookedAt', 'firstName', 'lastName', 'city', 'type', 'avatarId', 'state']),

])

const leadStore = useLeadStore()
const {
  lastUpdated,
} = storeToRefs(leadStore)

const { data: openLeads } = useLazyAsyncData(
  'open-leads',
  () => $fetch<{ documents: Lead[], total: number }>(`/api/v1/leads`, {
    baseURL: useRuntimeConfig().public.base.url,
    params: {
      query: query.value,
    },
  }),
  {
    default: (): { documents: Lead[], total: number, cursor?: string } => ({
      cursor: undefined,
      documents: [],
      total: 0,
    }),
    transform: ({ documents, total }: { documents: Lead[], total: number }) => ({
      cursor: documents[documents.length - 1].$id || null,
      documents,
      total,
    }),
    watch: [
      query,
      lastUpdated,
    ],
  },
)
// onMounted(() => execute())

const openLeadsWrapper = ref<HTMLElement | null>(null)

useInfiniteScroll(
  openLeadsWrapper,
  async () => {
    const { documents, total } = await $fetch<{ documents: Lead[], total: number }>(`/api/v1/leads`, {
      // baseURL: useRuntimeConfig().public.base.url,
      params: {
        query: [
          ...query.value,
          Query.cursorAfter(openLeads.value.cursor),
        ],
      },
    })
    openLeads.value.cursor = documents[documents.length - 1].$id || null
    openLeads.value.documents.push(...(documents as Lead[]))
    openLeads.value.total = total
  },
  {
    canLoadMore: () => openLeads.value.documents.length < openLeads.value.total,
    distance: itemHeight.value * 2,
    interval: 1000,
  },
)

const route = useRoute('leads-id')
const id = computed(() => route.params.id)
</script>

<template>
  <div>
    <div
      class="py-1 px-4 mb-2 text-body-1 font-weight-light d-flex align-center justify-space-between bg-surface"
      style="position:sticky; top:0; z-index: 1000;"
    >
      <span>offene Leads</span>
      <span
        v-if="openLeads.documents.length > 0"
        class="text-body-2 text-medium-emphasis"
      >{{ openLeads.total }}</span>
    </div>
    <!-- <v-card
      v-if="pending"
      class="d-flex align-center justify-center"
      :height="height"
      variant="flat"
    >
      <Icon
        class="text-primary" name="svg-spinners:pulse-multiple" size="52"
      />
    </v-card> -->

    <v-virtual-scroll
      v-if="openLeads.documents.length > 0"
      ref="openLeadsWrapper"
      :items="openLeads.documents"
      :height="height"
    >
      <template #default="{ item, index }">
        <v-list-item
          :key="item.$id"
          :active="id === item.$id"
          :value="item"
          class="pa-1 mb-2 text-caption rounded-xl px-2"
          :class="[
            index % 2 === 0 ? 'odd' : 'even',
          ]"
          color="primary"
          two-line
          @click="openLeadInfoSidebar(item.$id)"
        >
          <template #prepend>
            <v-badge
              dot :color="useColor().determineStatusColor(item.state)"
              @click.stop="navigateTo(`/leads/${item.$id}`)"
            >
              <v-avatar
                class="info-icon"
                color="surface-light"
                size="40"
              >
                <v-img
                  v-if="item.avatarId"
                  class="rounded-pill avatar"
                  alt="Avatar"
                  :src="`${useAppwrite().imagePreview('leadAvatars', item.avatarId)}?width=40&height=40&output=webp`"
                />
                <span v-else-if="item.lastName" class="avatar text-body-1 font-weight-thin">
                  {{ useString().getInitials(`${item.firstName} ${item.lastName}`) }}
                </span>
                <Icon class="info-icon" name="tabler:external-link" size="18" />
              </v-avatar>
            </v-badge>
            <v-tooltip
              activator="parent"
              content-class="elevation-0 rounded-xl text-body-1"
              location="bottom"
              text="Detailseite anzeigen"
            />
          </template>

          <div class="d-block text-truncate">
            {{ item.firstName }} {{ item.lastName }}, {{ item.city }}
          </div>
          <div
            class="d-block text-truncate"
          >
            {{ item.type }} {{ item.state }}
          </div>
        </v-list-item>
      </template>
    </v-virtual-scroll>

    <v-list
      v-else
      class="py-0 flex-grow-1 flex-shrink-1 overflow-y-auto"
      density="compact"
    >
      <v-list-item
        class="pa-1 px-3 mb-2 text-caption rounded-xl text-disabled"
        color="primary"
      >
        <div class="d-flex align-center">
          <Icon class="mr-2 text-success" name="tabler:checks" size="32" />
          <div class="d-block text-truncate text-body-2 font-weight-light">
            Keine offenen Leads
          </div>
        </div>
      </v-list-item>
    </v-list>
  </div>
</template>

<style lang="scss" scoped>
:deep() {
  .v-list-item__prepend {
    width: 56px;
  }
  .info-icon {
    * {
      transition-duration: 0.2s;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }

    svg {
      position: absolute;

      opacity: 0;
    }

    &:hover {
      .avatar {
        opacity: 0;
      }
      svg {
        opacity: 1;
      }
    }
  }
}
.v-list-item {
  &.odd {
    background-color: rgba(var(--v-theme-surface-light), 0.1);
  }

  &.even {
    background-color: rgba(var(--v-theme-surface-bride), 0.1);
  }
  &:hover {
    background: rgba(var(--v-theme-surface), var(--v-theme-overlay-multiplier));
  }
}
</style>
