<script lang="ts" setup>
const leadStore = useLeadStore()
const {
  lastUpdated,
} = storeToRefs(leadStore)

const query = ref([
  Query.equal('status', ['open', 'in-progress']),
  Query.orderDesc('expirationAt'),
])

const { data: tasks, execute, pending } = useLazyAsyncData(
  'list-tasks-sidebar',
  () => $fetch<{ documents: Task[], total: number }>(`/api/v1/tasks`, {
    baseURL: useRuntimeConfig().public.base.url,
    params: {
      query: query.value,
    },
  }),
  {
    default: (): { documents: Task[], total: number } => ({
      documents: [],
      total: 0,
    }),
    immediate: false,
    watch: [
      query,
      lastUpdated,
    ],
  },
)

onMounted(() => {
  execute()
})

const { getLead, setIsInfo, setIsOpen, setIsSchedule } = useRightSidebarStore()
function openLeadInfoSidebar(id: string) {
  getLead(id)
  setIsInfo(true)
  setIsOpen(true)
  // setIsSchedule(false)
}
</script>

<template>
  <v-list
    v-if="tasks.documents.length > 0"
    v-auto-animate
    class="pr-2"
  >
    <v-list-item
      v-for="(item, index) in tasks.documents"
      :key="item.$id"
      :active="false"
      :value="item"
      class="pa-1 pr-2 mb-2 text-caption rounded-xl"
      :class="[
        index % 2 === 0 ? 'odd' : 'even',
      ]"
      color="primary"
      two-line
      @click="openLeadInfoSidebar(item.leads.$id)"
    >
      <template #prepend>
        <!-- <v-btn
          class="mr-3"
          color="success"
          icon
          size="small"
          variant="text"
        >
          <Icon
            name="tabler:phone"
            size="24"
          />
        </v-btn> -->
        <v-btn
          class="mr-3"
          color="success"
          icon
          size="small"
          variant="text"
          @click.stop="useLeadStore().deleteLeadTask(
            item.leads.$id, item.$id as string)"
        >
          <Icon
            name="tabler:trash"
            size="24"
          />
        </v-btn>
      </template>
      <template #append>
        <v-btn
          class="ml-3"
          color="error"
          icon
          size="x-small"
          variant="text"
          @click.stop="useLeadStore().updateLeadNotes(
            item.leads.$id, {
              $id: undefined,
              createdAt: undefined,
              text: 'Lead nicht erreicht',
              type: 'callAttempt',
            })"
        >
          <Icon
            name="tabler:thumb-down"
            size="18"
          />
        </v-btn>
        <v-btn
          color="success"
          icon
          size="x-small"
          variant="text"
          @click="useLeadStore().updateLeadNotes(
            item.leads.$id, {
              $id: undefined,
              createdAt: undefined,
              text: 'Lead erreicht',
              type: 'callAttempt',
            }), useLeadStore().updateLeadTask(
            item.leads.$id, {
              $id: item.$id,
              status: 'completed',
            } as Task)"
        >
          <Icon
            name="tabler:thumb-up"
            size="18"
          />
        </v-btn>
      </template>
      <div
        class="d-block text-truncate" :class="{
          'text-decoration-line-through text-disabled': item.status === 'completed',
        }"
      >
        {{ item.title }}<br>
        {{ useDateFns().untilThen(item.expirationAt as Date) }}
        <v-tooltip
          activator="parent"
          content-class="elevation-0 rounded-xl text-body-1"
          location="top"
          :text="useDateFns().formatDate(item.expirationAt as Date)"
        />
      </div>
    </v-list-item>
  </v-list>
  <v-list
    v-else
    class="py-0 flex-grow-1 flex-shrink-1 overflow-y-auto"
    density="compact"
  >
    <v-list-item
      class="pa-1 px-3 mb-2 text-caption rounded-xl text-disabled"
      color="primary"
    >
      <div class="d-flex align-center">
        <Icon class="mr-2 text-success" name="tabler:checks" size="32" />
        <div class="d-block text-truncate text-body-2 font-weight-light">
          Keine offenen Erinnerungen / Termine
        </div>
      </div>
    </v-list-item>
  </v-list>
</template>

<style lang="scss" scoped>
:deep() {
  .v-list-item__content {
    overflow: hidden !important;
  }
}
.v-list-item {
  &.odd {
    background-color: rgba(var(--v-theme-surface-light), 0.1);
  }

  &.even {
    background-color: rgba(var(--v-theme-surface-bride), 0.1);
  }
  &:hover {
    background: rgba(var(--v-theme-surface), var(--v-theme-overlay-multiplier));
  }
}
</style>
