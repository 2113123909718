// Format file size
export function formatFileSize(size: number): string {
  const units = ['B', 'KB', 'MB', 'GB', 'TB']
  let unitIndex = 0

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024
    unitIndex++
  }

  return `${size.toFixed(2)} ${units[unitIndex]}`
}

// 判断当前设备是否为移动端
export function isMobile(): boolean {
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
}

// scroll to top
interface ScrollOptions {
  behavior?: 'auto' | 'smooth'
  block?: 'start' | 'center' | 'end' | 'nearest'
  inline?: 'start' | 'center' | 'end' | 'nearest'
  top?: number
}

export function scrollToTop(element: HTMLElement | null, options: ScrollOptions = { behavior: 'auto', top: 0 }): void {
  if (!element) {
    console.error('Element not found')

    return
  }
  element.scrollTo({
    ...options,
  })
}

// scroll to bottom
export function scrollToBottom(element: HTMLElement | null, options: ScrollOptions = { behavior: 'auto' }): void {
  element?.scrollTo({
    ...options,
    top: element.scrollHeight,
  })
}
