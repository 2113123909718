<!-- eslint-disable perfectionist/sort-objects -->
<script lang="ts" setup>
const rightSidebarStore = useRightSidebarStore()
const { isInfo, isInfoLocked, isInfoPermanent, title, lead, leadCustomised } = storeToRefs(rightSidebarStore)

const pending = ref(false)
</script>

<template>
  <div>
    <v-navigation-drawer
      v-model="isInfo"
      class="multi-drawer elevation-0 rounded-s-xl"
      color="surface-light"
      disable-route-watcher
      floating
      location="right"
      :scrim="false"
      width="450"
      :permanent="isInfoPermanent"
      :temporary="!isInfoPermanent"
      style="z-index: 1005;"
    >
      <template #prepend>
        <v-toolbar
          class="d-flex align-center justify-space-between rounded-ts-xl"
          color="surface-light"
          density="compact"
        >
          <template #prepend>
            <v-list
              v-if="lead"
              class="pa-0 rounded-ts-xl"
              bg-color="surface-light"
              width="56"
            >
              <v-list-item
                class="pa-0 d-flex align-center justify-center rounded-be-lg"
                @click="isInfo = !isInfo"
              >
                <v-avatar
                  class="info-icon"
                  color="surface"
                  size="40"
                >
                  <v-img
                    v-if="lead.avatarId"
                    class="rounded-pill avatar"
                    alt="Avatar"
                    :src="`${useAppwrite().imagePreview('leadAvatars', lead.avatarId)}?width=40&height=40&output=webp`"
                  />
                  <span v-else-if="lead.lastName" class="avatar text-body-1 font-weight-thin">
                    {{ useString().getInitials(`${lead.firstName} ${lead.lastName}`) }}
                  </span>

                  <Icon class="info-icon" name="tabler:chevron-right" size="24" />
                </v-avatar>
                <v-tooltip
                  :disabled="isInfo"
                  activator="parent"
                  content-class="bg-surface elevation-0 rounded-s-xl text-body-1"
                  location="left"
                  text="Information schließen"
                />
              </v-list-item>
            </v-list>
          </template>
          <v-toolbar-title class="text-subtitle-2">
            {{ title }}
          </v-toolbar-title>
          <template #append>
            <v-btn
              class="mr-2"
              :color="isInfoPermanent ? 'primary' : ''"
              icon
              size="x-small"
              variant="text"
              @click="isInfoPermanent = !isInfoPermanent"
            >
              <Icon
                :name="isInfoPermanent ? 'tabler:arrow-bar-to-right' : 'tabler:arrow-bar-left'"
                size="18"
              /><v-tooltip
                activator="parent"
                content-class="bg-background elevation-0 rounded-xl text-body-1"
                location="bottom"
                :text="`Information ${isInfoPermanent ? 'überlagernd' : 'fest'}  anzeigen`"
              />
            </v-btn>
            <v-btn
              class="mr-n2"
              :color="isInfoLocked ? 'primary' : ''"
              icon
              size="x-small"
              variant="text"
              @click="isInfoLocked = !isInfoLocked"
            >
              <Icon
                :name="isInfoLocked ? 'tabler:lock' : 'tabler:lock-open'"
                size="18"
              /><v-tooltip
                activator="parent"
                content-class="bg-background elevation-0 rounded-xl text-body-1"
                location="bottom"
                :text="`Information immer anzeigen ${isInfoLocked ? 'deaktivieren' : 'aktivieren'}`"
              />
            </v-btn>
          </template>
        </v-toolbar>
      </template>
      <v-fade-transition leave-absolute hide-on-leave>
        <div
          v-if="lead && leadCustomised"
          :key="lead.$id"
          class="pl-4 pr-2 mr-1 h-100 d-flex flex-column elevation-0 text-body-2 overflow-auto overflow-x-hidden"
          style="position: relative;
        "
        >
          <!-- <pre>{{ lead }}</pre> -->
          <!-- <span>{{ lead?.name }}</span> -->
          <div
            class="rounded-b-xl bg-surface-light pa-4"
            style="
          position:sticky;
          top:0;
          z-index:20
          "
          >
            <v-row class="text-caption">
              <v-col cols="6" class="py-2 d-flex align-center ">
                Sparte:
                <v-chip
                  class="ml-2"
                  size="small"
                  variant="flat"
                >
                  {{ lead.type }}
                </v-chip>
              </v-col>
              <v-col cols="6" class="py-2 d-flex align-center justify-end">
                <span class="px-3">{{ useDateFns().untilNow(lead.bookedAt as Date) }} gebucht</span>
              </v-col>
            </v-row>
            <v-row class="text-caption">
              <v-col cols="6" class="pt-1 pb-4 d-flex align-center ">
                Stufe:
                <LeadStateChip
                  class="ml-2"
                  variant="tonal"
                  size="small"
                  :state="lead.state"
                />
              </v-col>
              <v-col cols="6" class="pt-1 pb-4 d-flex align-center justify-end">
                <LeadStateUpdateButton
                  v-if="lead?.states.length > 0"
                  v-model="lead"
                  size="small"
                  variant="text"
                />
              </v-col>
            </v-row>

            <v-row class="text-caption">
              <v-col cols="6" class="py-2 flex-column d-flex align-start justify-center">
                <div class="">
                  {{ lead.salutation }}
                </div>
                <div class="">
                  {{ lead.firstName }} {{ lead.lastName }}
                </div>
                <div class="">
                  {{ leadCustomised?.address }}
                </div>
                <div class="">
                  {{ leadCustomised?.postalCode }} {{ leadCustomised?.city }}
                </div>
              </v-col>
              <v-col cols="6" class="py-2 flex-column d-flex align-start justify-center">
                <div class="">
                  <Icon
                    class="mr-2"
                    name="tabler:briefcase"
                    size="12"
                  />{{ lead.professionalStatus }}
                </div>
                <div class="">
                  <Icon
                    class="mr-2"
                    name="tabler:cake"
                    size="12"
                  />
                  {{ useDateFns().formatDate(lead.birthday as Date, 'dd.MM.yyyy') }}
                </div>
                <div class="">
                  <Icon
                    class="mr-2"
                    name="tabler:mail"
                    size="12"
                  />{{ leadCustomised.email }}
                </div>
                <div class="">
                  <Icon
                    class="mr-2"
                    name="tabler:phone"
                    size="12"
                  />{{ leadCustomised?.phone }}
                </div>
                <div v-if="lead.phone2" class="">
                  <Icon
                    class="mr-2"
                    name="tabler:phone"
                    size="12"
                  />{{ leadCustomised?.phone2 }}
                </div>
              </v-col>
            </v-row>

            <v-row class="text-caption">
              <div
                class="py-4 d-flex ga-2 align-center justify-center w-100"
                color="surface-light"
                density="compact"
              >
                <AppQrPopover
                  :copy-text="lead.phone"
                  :link="`tel:${lead.phone}`"
                  title="Anrufen"
                  :button-props="{
                    class: 'bg-surface',
                    icon: true,
                    size: 'x-small',
                    variant: 'text',
                  }"
                  :menu-props="{ openOnHover: true, location: 'top' }"
                  :size="120"
                  @click.stop
                >
                  <Icon
                    class="text-medium-emphasis"
                    name="tabler:phone"
                    size="18"
                  />
                </AppQrPopover>

                <AppQrPopover
                  :copy-text="`https://wa.me/${lead.phone.replace('+', '')}`"
                  :link="`https://wa.me/${lead.phone.replace('+', '')}`"
                  title="Whatsapp"
                  :button-props="{
                    class: 'bg-surface',
                    icon: true,
                    size: 'x-small',
                    variant: 'text',
                  }"
                  :menu-props="{ openOnHover: true, location: 'top' }"
                  :size="120"
                  @click.stop
                >
                  <Icon
                    class="text-medium-emphasis"
                    name="tabler:brand-whatsapp"
                    size="18"
                  />
                </AppQrPopover>

                <AppQrPopover
                  :copy-text="lead.email"
                  :link="`mailto:${lead.email}`"
                  title="E-Mail"
                  :button-props="{
                    class: 'bg-surface',
                    icon: true,
                    size: 'x-small',
                    variant: 'text',
                  }"
                  :menu-props="{ openOnHover: true, location: 'top' }"
                  :size="120"
                  @click.stop
                >
                  <Icon
                    class="text-medium-emphasis"
                    name="tabler:mail"
                    size="18"
                  />
                </AppQrPopover>

                <TaskCreate
                  :lead-id="lead.$id"
                  @created-task="emit('createdTask')"
                >
                  <v-btn
                    class="ml-4"
                    icon
                    size="x-small"
                    variant="text"
                  >
                    <Icon
                      name="tabler:calendar-plus"
                      size="18"
                    />
                    <v-tooltip
                      activator="parent"
                      content-class="bg-background elevation-0 rounded-xl text-body-1"
                      location="bottom"
                      text="Erinnerung anlegen"
                    />
                  </v-btn>
                </TaskCreate>

                <InputDateTime
                  :date-props="{ min: new Date() }"
                  :menu-props="{ location: 'bottom center' }"
                  :loading="pending"
                  @create-task="createCallTask($event as Date)"
                >
                  <v-btn
                    icon
                    size="x-small"
                    variant="text"
                  >
                    <Icon
                      name="tabler:phone-plus"
                      size="18"
                    /><v-tooltip
                      activator="parent"
                      content-class="bg-background elevation-0 rounded-xl text-body-1"
                      location="bottom"
                      text="Anruf-Erinnerung anlegen"
                    />
                  </v-btn>
                </InputDateTime>

                <OfferCreate
                  :name="`${lead.firstName} ${lead.lastName}`"
                  :lead-id="lead.$id"
                  :type="lead.type"
                >
                  <v-btn
                    icon
                    size="x-small"
                    variant="text"
                  >
                    <Icon
                      name="tabler:file-plus"
                      size="18"
                    /><v-tooltip
                      activator="parent"
                      content-class="bg-background elevation-0 rounded-xl text-body-1"
                      location="bottom"
                      text="Angebot erstellen"
                    />
                  </v-btn>
                </OfferCreate>
                <v-btn
                  class="ml-2"
                  icon
                  size="x-small"
                  variant="text"
                  :href="`https://www.google.com/search?q=${leadCustomised?.firstName}+${leadCustomised?.lastName}+${leadCustomised?.city}`"
                  target="_blank"
                >
                  <Icon
                    name="logos:google-icon"
                    size="18"
                  /><v-tooltip
                    activator="parent"
                    content-class="bg-background elevation-0 rounded-xl text-body-1"
                    location="bottom"
                    text="Lead im neuem Fenster recherieren"
                  />
                </v-btn>
              </div>
            </v-row>
          </div>

          <LeadDetailTaskList :lead-id="lead.$id" :tasks="lead.tasks" size="small" />

          <LeadDetailNoteList :lead-id="lead.$id" :notes="lead.notes" size="small" />
          <div
            class="pa-0 mt-4 pb-4"
          >
            <v-card-title
              class="py-2 font-weight-light d-flex align-center justify-space-between"
              style="min-height:52px"
            >
              Zusatzinformation
            </v-card-title>
            <v-table
              class="bg-transparent rounded-0 text-body-2 font-weight-light"
              density="compact"
            >
              <tbody>
                <tr
                  v-for="detail in useLead().parseInfos(lead.details)"
                  :key="detail.answer"
                >
                  <td class="py-1 rounded-s-xl text-caption">
                    {{ detail.question }}
                  </td>
                  <td class="text-wrap py-1 rounded-e-xl text-caption">
                    <NuxtLink v-if="detail.answer.startsWith('http')" external :href="detail.answer" target="_blank">
                      {{ detail.answer }}
                    </NuxtLink>
                    <span v-else>{{ detail.answer }}</span>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </div>
        </div>
      </v-fade-transition>

      <!-- ---------------------------------------------- -->
      <!---Bottom Area -->
      <!-- ---------------------------------------------- -->
      <template #append>
        <!-- <v-list
          class="py-0 rounded-bs-xl"
          bg-color="surface-light"
          variant="plain"
        >
          <v-list-item
            class="pa-0 d-flex align-center justify-center"
            rounded="0"
            :to="`/leads/${lead?.$id}`"
          >
            <Icon name="tabler:user-search" size="24" />
            <v-tooltip
              activator="parent"
              content-class="bg-background elevation-0 rounded-xl text-body-1"
              location="top"
              :text="`Details zu ${lead?.firstName} ${lead?.lastName} anzeigen`"
            />
          </v-list-item>
        </v-list> -->
        <div v-if="lead" class="pa-2 pt-4 d-flex align-center justify-center ga-2">
          <v-btn
            :aria-label="`Details zu ${leadCustomised?.firstName} ${leadCustomised?.lastName} anzeigen`"
            class=""
            size="small"
            variant="text"
            :to="`/leads/${lead?.$id}`"
          >
            <Icon
              class="text-primary text-medium-emphasis mr-2" name="tabler:external-link" size="18"
            />
            <span>
              Details anzeigen
            </span>
            <v-tooltip
              activator="parent"
              content-class="bg-background elevation-0 rounded-xl text-body-1"
              location="top"
              :text="`Details zu ${leadCustomised?.firstName} ${leadCustomised?.lastName} anzeigen`"
            />
          </v-btn>

          <!-- <v-btn
            aria-label="Erinnerung anlegen"
            class="bg-surface"
            icon
            size="x-small"
            variant="text"
          >
            <Icon
              class="text-medium-emphasis"
              name="tabler:calendar-plus"
              size="18"
            />
            <v-tooltip
              activator="parent"
              content-class="elevation-0 rounded-xl text-body-1"
              location="bottom"
              text="Erinnerung anlegen"
            />
          </v-btn>

          <v-btn
            aria-label="neues Angebot erstellen"
            class="bg-surface"
            icon
            size="x-small"
            variant="text"
          >
            <Icon
              class="text-medium-emphasis"
              name="tabler:file-plus"
              size="18"
            />
            <v-tooltip
              activator="parent"
              content-class="elevation-0 rounded-xl text-body-1"
              location="bottom"
              text="neues Angebot erstellen"
            />
          </v-btn> -->

          <!-- <AppQrPopover
            :copy-text="lead.phone"
            :link="`tel:${lead.phone}`"
            title="Anrufen"
            :button-props="{
              class: 'bg-surface',
              icon: true,
              size: 'x-small',
              variant: 'text',
            }"
            :menu-props="{ openOnHover: true, location: 'top' }"
            :size="120"
            @click.stop
          >
            <Icon
              class="text-medium-emphasis"
              name="tabler:phone"
              size="18"
            />
          </AppQrPopover>

          <AppQrPopover
            :copy-text="lead.email"
            :link="`mailto:${lead.email}`"
            title="E-Mail"
            :button-props="{
              class: 'bg-surface',
              icon: true,
              size: 'x-small',
              variant: 'text',
            }"
            :menu-props="{ openOnHover: true, location: 'top' }"
            :size="120"
            @click.stop
          >
            <Icon
              class="text-medium-emphasis"
              name="tabler:mail"
              size="18"
            />
          </AppQrPopover> -->

        <!-- <LeadStateUpdateButton
            v-if="lead?.states.length > 0"
            :lead="lead"
            class="bg-surface text-body-1 font-weight-light text-medium-emphasis"
            height="40"
            size="small"
            variant="plain"
          /> -->
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
.v-navigation-drawer {
  max-height: calc(100dvh - 104px) !important;
  z-index: 2200 !important;
  transition-property: background-color, box-shadow, transform, visibility, y,
    width, height, left, right, top, bottom, margin-right;
}
:deep() {
  .v-toolbar__prepend {
    margin-inline: 0 auto !important;
  }
  .v-navigation-drawer__content {
    // overflow-y: hidden;
    background: rgb(var(--v-theme-surface-light)) !important;
    // margin-right: 8px;
    display: flex;
    flex-direction: column;

    .v-list-item__content {
      overflow: visible;
    }

    textarea {
      font-size: 0.75rem;
    }
  }
  .info-icon {
    * {
      transition-duration: 0.2s;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }

    svg {
      position: absolute;

      opacity: 0;
    }

    &:hover {
      .avatar {
        opacity: 0;
      }
      svg {
        opacity: 1;
      }
    }
  }
  .v-table__wrapper {
    overflow: hidden !important;

    table {
      // white-space: nowrap;

      tr {
        padding: 4px 0;

        td {
          // vertical-align: top !important;
          // border: none !important;
          // height: auto !important;
        }
      }
    }
  }
}
</style>
