<script setup lang="ts">
import { useChat } from 'ai/vue'
import { UseDraggable as Draggable } from '@vueuse/components'
import { useDisplay } from 'vuetify'

const langs = [
  {
    code: 'en',
    label: 'English',
    name: 'English',
  },
  {
    code: 'fr',
    label: 'Français',
    name: 'French',
  },
  {
    code: 'de',
    label: 'Deutsch',
    name: 'German',
  },
  {
    code: 'es',
    label: 'Español',
    name: 'Spanish',
  },
]

const currentLang = ref({
  code: 'en',
  label: 'English',
  name: 'English',
})
function setLang(lang: any) {
  currentLang.value = lang
}

const baseContent = ref('')
const targetContent = ref('')

const { append, input, isLoading, messages } = useChat({
  api: `${useRuntimeConfig().public.base.url}/api/v1/ai/completion`,
  // body,
  headers: { 'Content-Type': 'application/json' },
})

const prompt = computed(() => {
  return `Translate into ${currentLang.value.name}: ${input.value}`
  //   return `I want you to act as an ${currentLangName.value} translator, spelling corrector and improver. I will speak to you in any language and you will detect the language, translate it and answer in the corrected and improved version of my text, in ${currentLang.value.name}. I want you to replace my simplified A0-level words and sentences with more beautiful and elegant, upper level ${currentLang.value.name} words and sentences. Keep the meaning same, but make them more literary. I want you to only reply the correction, the improvements and nothing else, do not write explanations.”`;
})

async function translate() {
  if (input.value === '') {
    Notify.error('Bitte geben Sie den Inhalt ein, den Sie übersetzen möchten')

    return
  }
  try {
    await append({ content: prompt.value, role: 'user' })
    const assistantMessage = messages.value.find(({ role }) => role === 'assistant')
    targetContent.value = assistantMessage ? assistantMessage.content : ''
    input.value = ''
    messages.value = []
  }
  catch (error: any) {
    Notify.error(error.message)
  }
}

const isBaseContentEmpty = ref(false)
// const recorder = ref<any>();
// const isRecording = ref(false);

// const startRecording = async () => {
//   // 获取用户媒体权限，视频的话参数{audio: true, video: true}
//   navigator.mediaDevices
//     .getUserMedia({ audio: true })
//     .then((stream) => {
//       // 创建媒体流
//       recorder.value = new MediaRecorder(stream);
//       const audioChunks = <any>[];
//       // 录音开始
//       recorder.value.start();
//       isRecording.value = true;
//       // 录音数据
//       recorder.value.ondataavailable = (e: any) => {
//         audioChunks.push(e.data);
//       };
//       // 录音结束
//       recorder.value.onstop = async (e: any) => {
//         const blob = new Blob(audioChunks, { type: "audio/wav" });
//         const file = new File([blob], "recording.wav", {
//           type: "audio/wav",
//         });
//         const formData = new FormData();
//         formData.append("file", file);
//         formData.append("model", "whisper-1");
//         const res = await createTranscriptionApi(
//           formData,
//           chatGPTStore.getApiKey
//         );
//         baseContent.value = res.data.text;
//       };
//     })
//     .catch((error) => {
//       Notify.error(error.message)
//     });
// };

// const stopRecording = () => {
//   if (recorder.value) {
//     recorder.value.stop();
//     isRecording.value = false;
//   }
// };

// const record = () => {
//   if (isRecording.value) {
//     stopRecording();
//   } else {
//     startRecording();
//   }
// };

const dialog = ref(false)
const { xs } = useDisplay()

// function readText() {
// //   const speech = useSpeechSynthesis(targetContent.value, {
// //   lang: 'de-DE',
// //   pitch: 1,
// //   rate: 1,
// //   volume: 1,
// // })
// }
const handle = ref<HTMLElement | null>(null)

const { height, width } = useDisplay()
const { mainRect } = useLayout()
const initialValue = computed(() => {
  return {
    x: xs.value ? 0 : width.value - mainRect.value.right - 600 - 16,
    y: height.value - 583 - 16,
  }
})

function handleKeydown(e: KeyboardEvent) {
  if (e.key === 'Enter' && (e.altKey || e.shiftKey)) {
    // Fügt ein Zeilenumbruchzeichen ein, wenn Alt oder Umschalt und Eingabe gleichzeitig gedrückt werden
    e.preventDefault()
    input.value += '\n'
  }
  else if (e.key === 'Enter') {
    // Nachricht senden, wenn nur die Eingabetaste gedrückt wird
    e.preventDefault()
    translate()
    // handleSubmit(input.value)
  }
}
const windowId = 'translationAssistant' // Eindeutige ID für jedes Fenster
const windowManagerStore = useWindowManagerStore()
const { activeWindowId } = storeToRefs(windowManagerStore)
const zIndex = computed(() => windowManagerStore.windows[windowId] || 100)

function bringToFront() {
  windowManagerStore.bringToFront(windowId)
}

watch(() => dialog.value, (value) => {
  if (value)
    bringToFront()
})
</script>

<template>
  <span @click="dialog = !dialog">
    <slot>
      <v-btn icon variant="text">
        <Icon name="tabler:language" size="24" />
        <v-tooltip activator="parent" location="left" text="Übersetzer Assistent" />
      </v-btn>
    </slot>
  </span>

  <teleport to="body">
    <v-fade-transition>
      <Draggable
        :initial-value="initialValue"
        :prevent-default="false"
        :handle="handle"
        style="
          position: fixed;
        "
        :style="{ zIndex }"
        @click="bringToFront"
        @move="bringToFront"
      >
        <v-card
          v-if="dialog"
          border="surface thin"
          class="dialog-bottom d-flex flex-column"
          :width="xs ? '100%' : '600px'"
        >
          <v-hover v-slot="{ isHovering, props }">
            <v-card-title
              ref="handle"
              class="py-2 font-weight-light d-flex align-center justify-space-between cursor-move"
              :class="{
                'bg-surface-variant': activeWindowId !== windowId && !isHovering,
                'bg-surface-light': activeWindowId === windowId || isHovering,
              }"
              v-bind="props"
            >
              <span class="d-fleign-center ga-2">
                <Icon v-if="!isLoading" name="tabler:language" size="24" />
                <Icon v-else class="text-primary" name="svg-spinners:pulse-multiple" size="24" />

                OpenAi {{ $t("toolbox.translationAssistant.title") }}
              </span>

              <v-btn aria-label="schließen" icon size="x-small" variant="text" @click.stop="dialog = false">
                <Icon class="text-medium-emphasis" name="tabler:x" size="18" />
              </v-btn>
            </v-card-title>
          </v-hover>
          <v-divider />
          <v-card-actions class="px-5">
            <span class="text-body-2">{{ $t("toolbox.translationAssistant.targetLanguage") }}:</span>
            <!-- <v-btn-toggle
            v-model="currentLang"
            density="compact"
            variant="outlined"
            color="primary"
            mandatory
          >
            <v-btn
              density="compact"
              size="small"
              v-for="lang in langs"
              :value="lang.code"
            >
              {{ lang.label }}
            </v-btn>
          </v-btn-toggle> -->
            <v-menu location="bottom end" scroll-y>
              <template #activator="{ props }">
                <v-btn width="108" append-icon="mdi-menu-down" v-bind="props">
                  <span class="text-body-2">{{ currentLang.label }}</span>
                </v-btn>
              </template>
              <v-card>
                <div v-for="lang in langs" :key="lang.code">
                  <v-btn block variant="text" @click="setLang(lang)">
                    {{ lang.label }}
                  </v-btn>
                </div>
              </v-card>
            </v-menu>

            <v-spacer />

            <v-btn
              class="ml-2 text-white"
              :disabled="!input || isLoading"
              variant="tonal"
              color="primary"
              @click="translate"
            >
              {{ $t("toolbox.translationAssistant.translate") }}
            </v-btn>
          </v-card-actions>
          <v-divider />
          <v-card-text>
            <v-row no-gutters justify="center" dense>
              <v-col cols="12">
                <v-card variant="flat">
                  <div class="pa-2">
                    <v-textarea
                      v-model="input"
                      auto-grow
                      clearable
                      hide-details
                      rounded="lg"
                      rows="5"
                      max-rows="5"
                      :placeholder="$t('toolbox.translationAssistant.sourceLanguagePlaceholder')"
                      @keydown="handleKeydown"
                      @focus="isBaseContentEmpty = false"
                    >
                      <template #clear>
                        <v-btn
                          icon
                          size="small"
                          variant="text"
                          style="position:absolute;right: 8px;top: 8px;"
                          @click="input = ''"
                        >
                          <Icon
                            name="tabler:trash"
                            size="18"
                          />
                        </v-btn>
                      </template>
                    </v-textarea>
                  </div>
                  <v-card-actions class="py-0 mb-2" style="min-height:32px">
                    <!-- <v-tooltip v-if="!isRecording" location="bottom" :text="$t('toolbox.translationAssistant.speech')">
                    <template #activator="{ props }">
                      <v-btn @click="record" v-bind="props" icon size="small" variant="text">

                        <Icon name="tabler:microphone" size="24" />
                      </v-btn>
                    </template>
                  </v-tooltip>
                  <v-tooltip location="bottom" :text="$t('toolbox.translationAssistant.stopSpeech')" v-else>
                    <template #activator="{ props }">
                      <v-btn @click="record" v-bind="props" icon size="small" variant="text">
                        <Icon name="tabler:player-stop-filled" size="24" />
                      </v-btn>
                    </template>
                  </v-tooltip> -->
                    <v-spacer />
                    <AppCopyBtn
                      :icon-props="{ size: '18' }"
                      :ripple="false"
                      :text="baseContent"
                      variant="plain"
                    />
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card variant="flat">
                  <div class="pa-2">
                    <!-- <v-textarea
                      v-model="targetContent"
                      auto-grow
                      clearable
                      hide-details
                      rounded="lg"
                      rows="5"
                      max-rows="5"
                      :placeholder="$t('toolbox.translationAssistant.targetLanguagePlaceholder')"
                    > -->
                    <v-textarea
                      v-model="targetContent"
                      readonly
                      auto-grow
                      clearable
                      hide-details
                      rounded="lg"
                      rows="5"
                      max-rows="5"
                    >
                      <template #clear>
                        <v-btn
                          icon
                          size="small"
                          variant="text"
                          style="position:absolute;right: 8px;top: 8px;"
                          @click="targetContent = ''"
                        >
                          <Icon
                            name="tabler:trash"
                            size="18"
                          />
                        </v-btn>
                      </template>
                    </v-textarea>
                  </div>
                  <v-card-actions class="py-0" style="min-height:32px">
                    <!-- <v-tooltip location="bottom" :text="$t('toolbox.translationAssistant.read')">
                    <template #activator="{ props }">
                      <v-btn @click="play" v-bind="props" icon size="small" variant="text">
                        <Icon name="tabler:volume" size="24" />
                      </v-btn>
                    </template>
                  </v-tooltip> -->
                    <v-spacer />
                    <AppCopyBtn
                      :icon-props="{ size: '18' }"
                      :ripple="false"
                      :text="targetContent"
                      variant="plain"
                    />
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
        </v-card>
      </Draggable>
    </v-fade-transition>
  </teleport>
</template>

<style scoped lang="scss">
.dialog-bottom {
  z-index: 3000;
  position: fixed;
  // bottom: 0px;
  // right: var(--v-layout-right);
  // margin-bottom: 8px;
  // margin-right: 8px;
}
</style>
