<script lang="ts" setup>
const leadStore = useLeadStore()
const {
  lastUpdated,
} = storeToRefs(leadStore)

const rightSidebarStore = useRightSidebarStore()

const {
  isAssistantMenu,
  isInfo,
  isInfoLocked,
  isOpen,
  isSchedule,
  lead,
  leadCustomised,
} = storeToRefs(rightSidebarStore)

function toggleInfo() {
  isInfo.value = !isInfo.value
}

function setChatCustomer(id: string) {
  if (id) {
    isOpen.value = true
    isInfo.value = isInfoLocked.value
    // isSchedule.value = false
    rightSidebarStore.getLead(id)
  }
}

// const messages = ref([
//   {
//     createdAt: '2024-03-14T11:37:03.067Z',
//     inbound: true,
//     text: 'Sehr gut, gerade das Angebot erhalten. Ich schaue es mir direkt an',
//   },
//   {
//     createdAt: '2024-03-14T11:34:04.565Z',
//     text: 'Vielen Dank für das freundliche Gespräch. Ich sende das Angebot soeben raus, wie wir besprochen haben',
//   },
//   {
//     createdAt: '2024-03-14T10:58:06.581Z',
//     inbound: true,
//     text: 'Danke',
//   },
//   {
//     createdAt: '2024-03-14T10:55:07.732Z',
//     text: 'Perfekt, ich melde mich 13 Uhr bei Ihnen',
//   },
//   {
//     createdAt: '2024-03-14T10:54:09.745Z',
//     inbound: true,
//     text: 'Hallo, heute 13 Uhr wäre optimal',
//   },
//   {
//     createdAt: '2024-03-14T10:15:41.690Z',
//     text: 'Guten Tag, ich habe gerade Ihre Anfrage zur PKV erhalten. Wann kann ich Sie am besten erreichen?',
//   },
// ])

const activeTaskTab = ref<'openCalls' | 'tasks' | 'messages' | 'openOffers'>('openCalls')
function openSchedule(value: 'openCalls' | 'tasks' | 'messages' | 'openOffers') {
  activeTaskTab.value = value
  isSchedule.value = true
  isOpen.value = true
}

const taskStore = useTaskStore()
const { openCallsCount } = storeToRefs(taskStore)
// taskStore.getTasks()

const { data, execute, pending } = useLazyAsyncData(
  'right-sidebar-data',
  async () => await Promise.all([
    $fetch('api/v1/leads', {
      baseURL: useRuntimeConfig().public.base.url,
      params: {
        query: [
          Query.select(['lastActiveAt', 'firstName', 'lastName', 'avatarId', 'state', '$id']),
          Query.limit(10),
          Query.orderDesc('lastActiveAt'),
          Query.greaterThan('lastActiveAt', useDateFns().subTimeToDate(new Date(), 2, 'd')),
        ],
      },
    }),
    $fetch('/api/v1/tasks', {
      baseURL: useRuntimeConfig().public.base.url,
      method: 'GET',
      params: {
        query: [
          Query.equal('status', ['open', 'in-progress']),
          Query.orderDesc('$createdAt'),
        ],
      },
    }),
  ]),
  {
    default: () => ({
      lastActiveLeads: [],
      openTasksTotal: 0,
    }),
    immediate: false,
    transform: ([
      lastActiveLeads,
      openTasks,
    ]) => {
      return {
        lastActiveLeads: (lastActiveLeads as { documents?: Lead[] }).documents || [],
        openTasksTotal: (openTasks as { total?: number }).total || 0,
      }
    },
    watch: [
      lastUpdated,
    ],
  },
)
onMounted(() => {
  execute()
})
</script>

<template>
  <div>
    <UiChatSidebar
      v-model:lead="lead"
      :active-task-tab
      color="surface-light"
    />
    <v-navigation-drawer
      order="0"
      class="multi-drawer elevation-0"
      :class="{
        'rounded-s-xl': !isInfo,
      }"
      color="surface-light"
      disable-route-watcher
      floating
      location="right"
      rail
      style="z-index: 1005;"
    >
      <!-- ---------------------------------------------- -->
      <!-- Top Area -->
      <!-- ---------------------------------------------- -->
      <template #prepend>
        <v-list
          class="pa-0 rounded-ts-xl"
          :class="{
            'rounded-ts-xl': !isInfo,
          }"
          bg-color="surface-light"
        >
          <v-list-item
            v-if="!isOpen"
            class="pa-0 d-flex align-center justify-center"
            @click="useRightSidebarStore().toggleIsOpen"
          >
            <template #prepend>
              <Icon name="tabler:arrow-bar-to-left" size="24" />
            </template>
          </v-list-item>
          <v-list-item
            v-else-if="isOpen && isSchedule && !isInfo"
            class="pa-0 d-flex align-center justify-center rounded-be-lg"
            @click="useRightSidebarStore().toggleIsOpen"
          >
            <template #prepend>
              <Icon name="tabler:arrow-bar-to-right" size="24" />
            </template>
          </v-list-item>
          <v-list-item
            v-else-if="isInfo"
            class="pa-0 d-flex align-center justify-center rounded-be-lg"
            disabled
            @click="toggleInfo"
          />
          <v-list-item
            v-else-if="lead && leadCustomised"
            class="pa-0 d-flex align-center justify-center rounded-be-lg"
            @click="toggleInfo"
          >
            <v-avatar
              class="info-icon"
              color="surface"
              size="40"
            >
              <v-img
                v-if="lead.avatarId"
                class="rounded-pill avatar"
                alt="Avatar"
                :src="`${useAppwrite().imagePreview('leadAvatars', lead.avatarId)}?width=40&height=40&output=webp`"
              />
              <span v-else-if="leadCustomised.lastName" class="avatar text-body-1 font-weight-thin">
                {{ useString().getInitials(`${leadCustomised.firstName} ${leadCustomised.lastName}`) }}
              </span>

              <Icon class="info-icon" name="tabler:info-circle" size="24" />
            </v-avatar>
            <v-tooltip
              :disabled="isInfo"
              activator="parent"
              content-class="bg-surface elevation-0 rounded-s-xl text-body-1"
              location="left"
              :text="`Details zu ${leadCustomised.firstName} ${leadCustomised.lastName} anzeigen`"
            />
          </v-list-item>
        </v-list>
      </template>
      <!-- ---------------------------------------------- -->
      <!-- Main Nav -->
      <!-- ---------------------------------------------- -->
      <v-list
        class="mt-3 pt-2 pb-0 flex-shrink-0"
      >
        <v-list-item
          :active="isSchedule && activeTaskTab === 'openCalls'"
          class="pa-0 py-2 d-flex align-center justify-center"
          :class="{ 'rounded-s-pill': isInfo }"
          rounded="0"
          @click="openSchedule('openCalls')"
        >
          <v-badge :content="openCallsCount " max="9">
            <Icon
              color="primary"
              name="tabler:phone"
              size="24"
            />
          </v-badge>
          <v-tooltip
            activator="parent"
            content-class="bg-surface elevation-0 rounded-s-xl text-body-1"
            location="left"
            :text="`${openCallsCount} offene Anrufe`"
          />
        </v-list-item>
        <v-list-item
          :active="isSchedule && activeTaskTab === 'tasks'"
          class="pa-0 py-2 d-flex align-center justify-center"
          :class="{ 'rounded-s-pill': isInfo }"
          rounded="0"
          @click="openSchedule('tasks')"
        >
          <v-badge :content="data.openTasksTotal" max="9">
            <Icon
              color="primary"
              name="tabler:calendar-time"
              size="24"
            />
          </v-badge>
          <v-tooltip
            activator="parent"
            content-class="bg-surface elevation-0 rounded-s-xl text-body-1"
            location="left"
            :text="`${data.openTasksTotal} Erinnerungen / Termine`"
          />
        </v-list-item>
        <!-- <v-list-item
          :active="isSchedule && activeTaskTab === 'messages'"
          class="pa-0 py-2 d-flex align-center justify-center"
          :class="{ 'rounded-s-pill': isInfo }"
          rounded="0"
          @click="openSchedule('messages')"
        >
          <v-badge content="12" max="9">
            <Icon
              name="tabler:mail"
              size="24"
            />
          </v-badge>

          <v-tooltip
            activator="parent"
            content-class="bg-surface elevation-0 rounded-s-xl text-body-1"
            location="left"
            text="7 ungelesene Nachrichten"
          />
        </v-list-item>
        <v-list-item
          :active="isSchedule && activeTaskTab === 'openOffers'"
          class="pa-0 py-2 d-flex align-center justify-center"
          :class="{ 'rounded-s-pill': isInfo }"
          rounded="0"
          @click="openSchedule('openOffers')"
        >
          <v-badge :content="2" max="9">
            <Icon
              name="tabler:report"
              size="24"
            />
          </v-badge>

          <v-tooltip
            activator="parent"
            content-class="bg-surface elevation-0 rounded-s-xl text-body-1"
            location="left"
            text="2 offene Angebote"
          />
        </v-list-item> -->
      </v-list>

      <v-divider class="ma-2" />

      <v-list
        v-auto-animate
        class="py-0 flex-grow-1 flex-shrink-1 overflow-y-auto"
      >
        <!-- <v-overlay
          :model-value="pending"
          class="align-start justify-center py-1"
          contained
          opacity="0"
          height="40"
        >
          <Icon class="text-primary" name="svg-spinners:pulse-multiple" size="40" />
        </v-overlay> -->
        <v-list-item
          v-for="item in data.lastActiveLeads"
          :key="item.$id"
          :active="lead?.$id === item.$id"
          base-color="primary"
          class="pa-0 d-flex align-center justify-center"
          :class="{ 'rounded-s-pill': isInfo }"
          @click="setChatCustomer(item.$id)"
        >
          <v-badge dot :color="useColor().determineStatusColor(item.state)">
            <v-avatar
              color="surface"
              size="40"
              style="padding: 1px;"
            >
              <v-img
                v-if="item.avatarId"
                class="rounded-pill"
                alt="Avatar"
                :src="`${useAppwrite().imagePreview('leadAvatars', item.avatarId)}?width=40&height=40&output=webp`"
              />
              <span
                v-else
                class="text-body-1 font-weight-thin"
              >
                {{ useString().getInitials(`${item.firstName} ${item.lastName}`) }}
              </span>
            </v-avatar>
          </v-badge>
          <v-tooltip
            activator="parent"
            content-class="bg-surface elevation-0 rounded-s-xl text-body-1"
            location="left"
            :text="`${item.firstName} ${item.lastName}`"
          />
        </v-list-item>
      </v-list>
      <!-- ---------------------------------------------- -->
      <!-- Bottom Area -->
      <!-- ---------------------------------------------- -->
      <template #append>
        <v-list
          v-if="isAssistantMenu"
          class="py-0"
        >
          <v-divider class="ma-2" />
          <AiChatAssistant>
            <v-list-item
              class="pa-0 d-flex align-center justify-center"
              :class="{ 'rounded-s-pill': isInfo }"
              rounded="0"
              @click="() => {}"
            >
              <template #prepend>
                <Icon name="tabler:accessible" size="24" />
              </template>
              <v-tooltip
                activator="parent"
                content-class="bg-surface elevation-0 rounded-s-xl text-body-1"
                location="left"
                text="Chat Assistent"
              />
            </v-list-item>
          </AiChatAssistant>
          <AiTranslationAssistant>
            <v-list-item
              class="pa-0 d-flex align-center justify-center"
              :class="{ 'rounded-s-pill': isInfo }"
              rounded="0"
              @click="() => {}"
            >
              <template #prepend>
                <Icon name="tabler:language" size="24" />
              </template>
              <v-tooltip
                activator="parent"
                content-class="bg-surface elevation-0 rounded-s-xl text-body-1"
                location="left"
                text="Übersetzer Assistent"
              />
            </v-list-item>
          </AiTranslationAssistant>
        </v-list>
        <v-list
          class="py-0 rounded-bs-xl"
          :class="{
            'rounded-be-xl': isOpen,
            'rounded-ts-xl': isInfo,
          } "
        >
          <v-list-item
            class="pa-0 d-flex align-center justify-center"
            @click="isAssistantMenu = !isAssistantMenu"
          >
            <template #prepend>
              <Icon :name="isAssistantMenu ? 'tabler:x' : 'tabler:brand-openai'" size="24" />
            </template>
            <v-tooltip
              activator="parent"
              content-class="bg-surface elevation-0 rounded-s-xl text-body-1"
              location="left"
              :text="isAssistantMenu ? 'Assistent schließen' : 'Assistent öffnen'"
            />
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <UiCustomerInfoSidebar />
  </div>
</template>

<style lang="scss" scoped>
.multi-drawer {
  z-index: 2400 !important;
  max-height: calc(100dvh - 104px) !important;
  transition-property: background-color, box-shadow, transform, visibility, y,
    width, height, left, right, top, bottom, margin-right;
}
.v-navigation-drawer {
  max-height: calc(100dvh - 104px) !important;
}
.bg-gradiant {
  background-color: rgba(var(--v-theme-surface-light), 1);
  background: radial-gradient(
      at bottom left,
      rgba(var(--v-theme-surface-bright), 0.25),
      transparent
    ),
    radial-gradient(
      at bottom right,
      rgba(var(--v-theme-surface), 0.75),
      transparent
    ),
    radial-gradient(
      500px at top center,
      rgba(var(--v-theme-surface-variant), 0.55),
      transparent
    ),
    radial-gradient(
      at bottom right,
      rgba(var(--v-theme-surface), 0.25),
      transparent
    ) !important;
}
.bg-glass-surface {
  background: rgba(var(--v-theme-surface), 0.65) !important;
  backdrop-filter: blur(6px) !important;
}
:deep() {
  .v-navigation-drawer__content {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;

    .v-list-item__content {
      overflow: visible;
      display: flex;
    }

    textarea {
      font-size: 0.75rem;
    }
  }
}
.info-icon {
  * {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  svg {
    position: absolute;

    opacity: 0;
  }

  &:hover {
    .avatar {
      opacity: 0;
    }
    svg {
      opacity: 1;
    }
  }
}
</style>
