<script setup lang="ts">
import { useChat } from 'ai/vue'
import { UseDraggable as Draggable } from '@vueuse/components'

const dialog = ref(false)
const { xs } = useDisplay()

const handle = ref<HTMLElement | null>(null)

const { height, width } = useDisplay()
const { mainRect } = useLayout()
const initialValue = computed(() => {
  return {
    x: xs.value ? 0 : width.value - mainRect.value.right - 600 - 16,
    y: height.value - 620 - 16,
  }
})

const agentsList = [
  'Standard',
  'Finanzberater',
  'Korrektor',
  'Rechtsberater',
  'Verkäufer',
  'Versicherungsmakler',
  // 'ChatGPT-Prompt-Generator',
]
const selectedAgent = ref(agentsList[0]) // Set 'General' as the default agent
const chatStopped = ref(false)

const body = computed(() => {
  return { agent: selectedAgent.value }
})

const { append, handleSubmit, input, isLoading, messages, reload, setMessages } = useChat({
  api: `${useRuntimeConfig().public.base.url}/api/v1/ai/completion`,
  body,
  headers: { 'Content-Type': 'application/json' },
})

function handleKeydown(e: KeyboardEvent) {
  if (e.key === 'Enter' && (e.altKey || e.shiftKey)) {
    // Fügt ein Zeilenumbruchzeichen ein, wenn Alt oder Umschalt und Eingabe gleichzeitig gedrückt werden
    e.preventDefault()
    input.value += '\n'
  }
  else if (e.key === 'Enter') {
    // Nachricht senden, wenn nur die Eingabetaste gedrückt wird
    e.preventDefault()
    append({ content: input.value, role: 'user' })
    input.value = ''
    // handleSubmit(input.value)
  }
}

const inputRow = ref(1)

// function changeAgent() {
//   // console.log(`Agent changed to: ${selectedAgent.value}`)
//   // Clear the chat messages when the agent is changed
//   // By using the setMessages method provided by the useChat composable
//   setMessages([])
// }

async function reloadChat() {
  chatStopped.value = false
  await reload()
}

// function stopChat() {
//   stop()
//   chatStopped.value = true // Set the chatStopped ref to true
// }

function sendPredefinedMessage(content: string) {
  append({
    content,
    role: 'user',
  })
}

const predefinedMessages = ref([
  'Email schreiben mit Nachfrage ob noch Interesse an der Privaten Krankenversicherung und den Vorteilen daraus besteht',
  'Was ist Selbstbeteiligung bei der pkv?',
  'Erkläre Selbstbeteiligung private Krankenversicherung kurz und knapp.',
  'Wieviel kostet die private Krankenversicherung?',
  'Angebotsanschreiben für private Kankenversicherung erstellen mit Erklärung der Selbstbeteiligung.',
  'Anschreiben an meinen Kunden erstellen ob noch Interesse an der privaten Krankenversicherung besteht.',
  'Kündigung zur privaten Krankenversicherung erstellen.',
  'Erkläre kurz und knapp den Risikozuschlag bei der privaten Krankenversicherung.',
  'Erkläre sehr ausführlich den Risikozuschlag bei der privaten Krankenversicherung.',
])

watch(
  () => messages.value,
  (val) => {
    if (val)
      scrollToBottom(document.querySelector('.message-wrapper'))
  },
  {
    deep: true,
  },
)

const { data: account, execute, pending } = useLazyAsyncData(
  'account',
  () => $fetch(`/api/v1/account`, {
    baseURL: useRuntimeConfig().public.base.url,
  }),
  {
    immediate: false,
  },
)
onMounted(() => {
  execute()
})

const windowId = 'chatAssistant' // Eindeutige ID für jedes Fenster
const windowManagerStore = useWindowManagerStore()
const { activeWindowId } = storeToRefs(windowManagerStore)
const zIndex = computed(() => windowManagerStore.windows[windowId] || 100)

function bringToFront() {
  windowManagerStore.bringToFront(windowId)
}

watch(() => dialog.value, (value) => {
  if (value)
    bringToFront()
})
</script>

<template>
  <span @click="dialog = !dialog">
    <slot>
      <v-btn icon variant="text">
        <Icon name="tabler:accessible" size="24" />
        <v-tooltip
          activator="parent"
          location="left"
          text="Chat Assistent"
        />
      </v-btn>
    </slot>
  </span>
  <teleport to="body">
    <v-fade-transition>
      <Draggable
        :initial-value="initialValue"
        :prevent-default="false"
        :handle="handle"
        style="
          position: fixed;
        "
        :style="{ zIndex }"
        @click="bringToFront"
        @move="bringToFront"
      >
        <v-card
          v-if="dialog"
          border="surface thin"
          class="dialog-bottom d-flex flex-column"
          :width="false ? '100%' : '600px'"
          height="628px"
        >
          <v-hover v-slot="{ isHovering, props }">
            <v-card-title
              ref="handle"
              class="py-2 font-weight-light d-flex align-center justify-space-between cursor-move ga-2"
              :class="{
                'bg-surface-variant': activeWindowId !== windowId && !isHovering,
                'bg-surface-light': activeWindowId === windowId || isHovering,
              }"
              v-bind="props"
            >
              <span class="d-flex align-center ga-2 flex-grow-1">
                <!-- <Icon v-if="!isLoading" name="tabler:brand-openai" size="24" /> -->
                <v-btn
                  v-if="!isLoading && messages?.length > 0"
                  aria-label="schließen"
                  icon
                  size="24"
                  variant="text"
                  @click.stop="setMessages([])"
                >
                  <Icon name="tabler:arrow-left" size="24" />
                </v-btn>
                <Icon v-else-if="!isLoading" name="tabler:brand-openai" size="24" />
                <Icon v-else class="text-primary" name="svg-spinners:pulse-multiple" size="24" />

                OpenAi Chat

              <!-- <div class="d-flex align-center mx-auto w-50">
                <v-autocomplete
                  v-model="selectedAgent"
                  class="ml-2"
                  density="compact"
                  :items="agentsList"
                  item-title="act"
                  item-value="prompt"
                  :label="$t('chatgpt.config.role')"
                  @update:model-value="changeAgent"
                />
              </div> -->
              </span>
              <v-btn
                aria-label="schließen"
                icon
                size="x-small"
                variant="text" @click.stop="dialog = false"
              >
                <Icon
                  class="text-medium-emphasis"
                  name="tabler:x"
                  size="18"
                />
              </v-btn>
            </v-card-title>
          </v-hover>
          <v-divider />
          <v-card-text
            class="message-wrapper overflow-y-auto px-0 py-2"
          >
            <div
              v-if="messages?.length > 0"
              class="message-container"
            >
              <template v-for="(message, index) in messages">
                <div v-if="message.role === 'user'" :key="`u-${index}`" class="px-2">
                  <div class="pa-2 user-message d-flex align-start justify-end ga-1">
                    <v-card class="gradient gray text-pre-wrap" variant="tonal">
                      <v-card-text class="font-weight-light">
                        {{ message.content }}
                      </v-card-text>
                    </v-card>
                    <v-avatar
                      class="mt-2 ml-2"
                      color="surface-light"
                      size="40"
                    >
                      <Icon
                        v-if="pending"
                        class="text-primary" name="svg-spinners:pulse-multiple" size="40"
                      />
                      <v-img
                        v-else-if="account?.prefs?.avatarId"
                        alt="Avatar"
                        :src="`${useAppwrite().imagePreview(
                          'userAvatars', account?.prefs?.avatarId)}?width=40&height=40&output=webp`"
                      >
                        <template #placeholder>
                          <Icon
                            v-if="pending"
                            class="text-primary" name="svg-spinners:pulse-multiple" size="40"
                          />
                        </template>
                      </v-img>
                      <Icon
                        v-else
                        name="tabler:user"
                        size="24"
                      />
                    </v-avatar>
                  </div>
                </div>
                <div v-else :key="`a-${index}`" class="px-2">
                  <div class="pa-2 d-flex align-start justify-start" style="position:relative">
                    <v-avatar
                      class="mt-2 mr-2"
                      color="surface-light"
                      size="40"
                    >
                      <!-- <Icon v-if="isLoading" class="text-primary" name="svg-spinners:pulse-multiple" />
                <Icon v-else name="tabler:brand-openai" size="24" /> -->
                      <Icon name="tabler:brand-openai" size="24" />
                    </v-avatar>

                    <div class="d-flex flex-column align-center justify-end">
                      <v-card class="gradient gray text-pre-wrap" variant="tonal">
                        <v-card-text class="font-weight-light">
                          {{ message.content }}
                        </v-card-text>
                      </v-card>
                      <div class="d-flex align-center justify-end ml-auto mr-2">
                        <v-btn
                          v-if="index === messages.length - 1"
                          icon
                          size="x-small"
                          :ripple="false"
                          variant="plain"
                          @click="reloadChat"
                        >
                          <Icon class="text-medium-emphasis" name="tabler:refresh" size="18" />
                        </v-btn>
                        <AppCopyBtn
                          :icon-props="{ size: '18' }"
                          :text="message.content"
                          :ripple="false"
                          variant="plain"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>

            <div v-else class="px-2 d-flex align-center">
              <v-row no-gutters justify="center">
                <v-col
                  v-for="(message, index) in predefinedMessages"
                  :key="index"
                  class="pa-2"
                  cols="4"
                >
                  <v-card
                    class="py-2 px-4 h-100 cursor-pointer"
                    height="100%"
                    hover
                    variant="flat"
                    style="border: 1px solid rgba(var(--v-theme-surface-variant), 1)"
                    @click="sendPredefinedMessage(message)"
                  >
                    {{ message }}
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-divider />

          <v-sheet
            class="d-flex align-end justify-center pa-2 ga-2"
            color="transparent"
            elevation="0"
          >
            <!-- <v-btn
              class="mb-2"
              icon
              size="small"
              variant="text"
              @click="configDialog = true"
            >
              <Icon name="tabler:settings" size="24" />
              <v-tooltip
                activator="parent"
                location="top"
                text="ChatGPT Config"
              />
            </v-btn> -->

            <v-textarea
              v-model="input"
              autofocus
              auto-grow
              clearable
              hide-details
              :placeholder="$t('toolbox.chatAssistant.messagePlaceholder')"
              rounded="lg"
              :rows="1"
              max-rows="5"
              @keydown="handleKeydown"
              @focus="inputRow = 2"
              @blur="inputRow = 1"
            >
              <template #clear>
                <v-btn
                  icon
                  variant="text"
                  size="x-small"
                  style="position:absolute;right: 8px;top: 8px;"
                  @click="input = ''"
                >
                  <Icon
                    name="tabler:trash"
                    size="18"
                  />
                </v-btn>
              </template>
            </v-textarea>

            <v-btn
              class="mb-1"
              :disabled="!input || isLoading"
              icon
              variant="tonal"
              @click="handleSubmit"
            >
              <Icon name="tabler:send-2" size="24" />
            </v-btn>
          </v-sheet>
        </v-card>
      </Draggable>
    </v-fade-transition>
  </teleport>
</template>
