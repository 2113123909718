<script lang="ts" setup>

</script>

<template>
  <v-navigation-drawer
    class="elevation-0 px-2 d-flex flex-column rounded-be-xl"
    color="surface"
    floating
    :width="260"
    permanent
  >
    <template #prepend>
      <v-card
        class="mx-2 elevation-0 d-flex align-center justify-space-between"
        color="transparent"
        variant="flat"
        height="104"
        rounded="0"
      >
        <NuxtLink
          to="/dashboard"
          aria-label="Brand"
          class="d-flex align-center justify-center flex-nowrap ga-3 text-decoration-none text-primary font-weight-normal text-h5"
        >
          <UiLogo
            background-color="rgb(var(--v-theme-surface-variant))"
            :size="44"
          />
          <!-- leadcloser -->
        </NuxtLink>
        <v-spacer />
        <div class="pr-3">
          <UiAppNotification />
        </div>
      </v-card>
    </template>
    <DashboardOpenLeads class="overflow-y-auto" />

    <template #append>
      <DashboardAdvisorManager class="mt-4" />
    </template>
  </v-navigation-drawer>
</template>

<style lang="scss" scoped>
.v-navigation-drawer {
  max-height: calc(100dvh - 52px) !important;
  position: fixed !important;
  box-shadow:
    rgba(var(--v-theme-surface), 0.4) 0px 2px 4px,
    rgba(var(--v-theme-surface), 0.3) 0px 7px 13px -3px,
    rgba(var(--v-theme-surface), 0.2) 0px -3px 0px inset !important;
}
:deep() {
  .v-navigation-drawer__content {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }
}
</style>
