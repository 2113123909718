<script lang="ts" setup>
import { UseDraggable as Draggable } from '@vueuse/components'
import { useDisplay } from 'vuetify'

const taskStore = useTaskStore()

onMounted(async () => {
  await taskStore.getOpenCalls()
})

const { openCalls } = storeToRefs(taskStore)
const appSettings = useAppSettingsStore()

const { settings } = storeToRefs(appSettings)

async function addNewTimeSlot() {
  await appSettings.addTimeSlot()
  scrollToBottom(document.querySelector('.message-wrapper'), { behavior: 'smooth' })
  taskStore.getOpenCalls()
}

function deleteTimeSlot(index: number) {
  appSettings.deleteTimeSlot(index)
  taskStore.getOpenCalls() // Aktualisiere OpenCalls nach einer Änderung
}

const dialog = ref(false)
const { xs } = useDisplay()

const handle = ref<HTMLElement | null>(null)

const { width } = useDisplay()
const { mainRect } = useLayout()
const initialValue = computed(() => {
  return {
    x: xs.value ? 0 : width.value - mainRect.value.right - 580 - 18 - 46,
    y: 104,
  }
})

const { getLead, setIsInfo, setIsOpen } = useRightSidebarStore()
function openLeadInfoSidebar(id: string) {
  getLead(id)
  setIsInfo(true)
  setIsOpen(true)
  // setIsSchedule(false)
}

const { updateLeadNotes } = useLeadStore()

function updateNotes(id: string, data: any) {
  console.log('updateLeadNotes', id)

  updateLeadNotes(
    id,
    data,
  )
}

const windowId = 'settingOpenCallsTimeSlots' // Eindeutige ID für jedes Fenster
const windowManagerStore = useWindowManagerStore()
const { activeWindowId } = storeToRefs(windowManagerStore)
const zIndex = computed(() => windowManagerStore.windows[windowId] || 100)

function bringToFront() {
  windowManagerStore.bringToFront(windowId)
}

watch(() => dialog.value, (value) => {
  if (value)
    bringToFront()
})
</script>

<template>
  <v-card
    class="d-flex flex-column text-caption overflow-y-auto flex-grow-1" rounded="0" variant="flat"
  >
    <teleport to="body">
      <v-fade-transition>
        <Draggable
          :initial-value="initialValue"
          :prevent-default="false"
          :handle="handle"
          style="
          position: fixed;
        "
          :style="{ zIndex }"
          @click="bringToFront"
          @move="bringToFront"
        >
          <v-card
            v-if="dialog"
            border="surface thin"
            class="dialog-bottom d-flex flex-column"
            :width="xs ? '100%' : '580px'"
            height="540px"
          >
            <v-hover v-slot="{ isHovering, props }">
              <v-card-title
                ref="handle"
                class="py-2 font-weight-light d-flex align-center justify-space-between cursor-move ga-2"
                :class="{
                  'bg-surface-variant': activeWindowId !== windowId && !isHovering,
                  'bg-surface-light': activeWindowId === windowId || isHovering,
                }"
                v-bind="props"
              >
                <span class="d-flex align-center ga-2 flex-grow-1">
                  <Icon name="tabler:settings" size="24" />
                  offene Anrufe Zeitfenster
                </span>
                <v-btn
                  aria-label="schließen"
                  icon
                  size="x-small"
                  variant="text" @click.stop="dialog = false"
                >
                  <Icon
                    class="text-medium-emphasis"
                    name="tabler:x"
                    size="18"
                  />
                </v-btn>
              </v-card-title>
            </v-hover>
            <v-divider />
            <v-card-text
              class="message-wrapper overflow-y-auto pa-4 pb-0"
            >
              <v-row
                v-for="(slot, index) in settings.dailyTasks.timeSlots"
                :key="index"
              >
                <v-col cols="12" class="d-flex align-center ga-2">
                  <v-text-field
                    v-model="slot.label"
                    density="compact"
                    label="Label"
                    @update:model-value="taskStore.getOpenCalls()"
                  />
                </v-col>

                <v-col cols="5" class="d-flex align-center ga-2">
                  <v-number-input
                    v-model="slot.from.hour"
                    density="compact"
                    :reverse="false"
                    control-variant="stacked"
                    hide-details
                    label="Stunde"
                    inset
                    :min="0"
                    :max="23"
                    variant="outlined"
                    @update:model-value="taskStore.getOpenCalls()"
                  >
                    <template #increment="{ click }">
                      <v-btn color="default" icon :ripple="false" variant="plain" @click="click">
                        <Icon class="mx-auto" name="tabler:plus" size="14" />
                      </v-btn>
                    </template>
                    <template #decrement="{ click }">
                      <v-btn color="default" icon :ripple="false" variant="plain" @click="click">
                        <Icon class="mx-auto" name="tabler:minus" size="14" />
                      </v-btn>
                    </template>
                  </v-number-input>
                  <v-number-input
                    v-model="slot.from.minute"
                    density="compact"
                    :reverse="false"
                    control-variant="stacked"
                    hide-details
                    label="Minute"
                    :min="0"
                    :max="59"
                    inset
                    variant="outlined"
                    @update:model-value="taskStore.getOpenCalls()"
                  >
                    <template #increment="{ click }">
                      <v-btn color="default" icon :ripple="false" variant="plain" @click="click">
                        <Icon class="mx-auto" name="tabler:plus" size="14" />
                      </v-btn>
                    </template>
                    <template #decrement="{ click }">
                      <v-btn color="default" icon :ripple="false" variant="plain" @click="click">
                        <Icon class="mx-auto" name="tabler:minus" size="14" />
                      </v-btn>
                    </template>
                  </v-number-input>
                </v-col>

                <v-col cols="1" class="d-flex align-center ga-2">
                  <Icon class="mx-auto" name="tabler:arrows-horizontal" size="24" />
                </v-col>
                <v-col cols="5" class="d-flex align-center ga-2">
                  <v-number-input
                    v-model="slot.to.hour"
                    density="compact"
                    :reverse="false"
                    control-variant="stacked"
                    hide-details
                    label="Stunde"
                    :min="0"
                    :max="23"
                    inset
                    variant="outlined"
                    @update:model-value="taskStore.getOpenCalls()"
                  >
                    <template #increment="{ click }">
                      <v-btn color="default" icon :ripple="false" variant="plain" @click="click">
                        <Icon class="mx-auto" name="tabler:plus" size="14" />
                      </v-btn>
                    </template>
                    <template #decrement="{ click }">
                      <v-btn color="default" icon :ripple="false" variant="plain" @click="click">
                        <Icon class="mx-auto" name="tabler:minus" size="14" />
                      </v-btn>
                    </template>
                  </v-number-input>
                  <v-number-input
                    v-model="slot.to.minute"
                    density="compact"
                    :reverse="false"
                    control-variant="stacked"
                    hide-details
                    label="Minute"
                    :min="0"
                    :max="59"
                    inset
                    variant="outlined"
                    @update:model-value="taskStore.getOpenCalls()"
                  >
                    <template #increment="{ click }">
                      <v-btn color="default" icon :ripple="false" variant="plain" @click="click">
                        <Icon class="mx-auto" name="tabler:plus" size="14" />
                      </v-btn>
                    </template>
                    <template #decrement="{ click }">
                      <v-btn color="default" icon :ripple="false" variant="plain" @click="click">
                        <Icon class="mx-auto" name="tabler:minus" size="14" />
                      </v-btn>
                    </template>
                  </v-number-input>
                </v-col>
                <v-col cols="1" class="d-flex align-center ga-2">
                  <v-btn class="ml-auto" icon size="x-small" variant="text" @click="deleteTimeSlot(index)">
                    <Icon name="tabler:trash" size="18" />
                  </v-btn>
                </v-col>

                <v-divider class="my-2" />
              </v-row>
            </v-card-text>
            <v-sheet
              class="d-flex align-end justify-center pa-2 ga-2"
              color="transparent"
              elevation="0"
            >
              <v-btn class="ml-auto" size="small" variant="text" @click="addNewTimeSlot">
                <Icon class="mr-2" name="tabler:plus" size="18" />
                Zeitraum anlegen
              </v-btn>
            </v-sheet>
          </v-card>
        </Draggable>
      </v-fade-transition>
    </teleport>
    <v-hover v-slot="{ isHovering, props }">
      <div
        v-bind="props"
      >
        <div
          class="py-0 pr-4 mb-2 text-body-2 font-weight-light d-flex align-center justify-space-between bg-surface"
          style="position:sticky; top:0; z-index: 1000; height:32px;"
        >
          <span>offene Anrufe</span>
          <!-- <span v-if="useLeadStore().openLeads.length > 0">{{ useLeadStore().openLeads.length }}</span> -->
          <v-fade-transition leave-absolute>
            <v-btn
              v-if="isHovering"
              aria-label="Einstellungen anzeigen"
              icon
              size="x-small"
              variant="text"
              @click="dialog = !dialog"
            >
              <Icon
                class="text-medium-emphasis"
                :name="dialog ? 'tabler:x' : 'tabler:settings'"
                size="18"
              />
            </v-btn>
          </v-fade-transition>
        </div>
        <v-list v-if="Object.keys(openCalls).length === 0 " class="pr-2 py-0">
          <v-list-item
            key="no-items"
            class="pa-1 px-3 mb-2 text-caption rounded-xl text-disabled"
            color="primary"
          >
            <div class="d-flex align-center">
              <Icon class="mr-2 text-success" name="tabler:checks" size="32" />
              <div class="d-block text-truncate text-body-2 font-weight-light">
                Keine offenen Anrufe
              </div>
            </div>
          </v-list-item>
        </v-list>
        <v-fade-transition group>
          <v-list v-for="(timeSlot, label) in openCalls" :key="label" v-auto-animate class="pr-2">
            <v-list-subheader
              v-if="timeSlot.leads.length > 0"
              class="mb-2 text-body-2 font-weight-light d-flex align-center justify-space-between"
            >
              {{ label }}
              ({{ useDateFns().formatTime(`${timeSlot.from.hour}:${timeSlot.from.minute}`) }}
              -
              {{ useDateFns().formatTime(`${timeSlot.to.hour}:${timeSlot.to.minute}`) }})
            </v-list-subheader>
            <template v-if="timeSlot.leads.length > 0">
              <v-list-item
                v-for="(item, index) in timeSlot.leads"
                :key="item.$id"
                class="pa-1 mb-2 text-caption rounded-xl"
                :class="[
                  index % 2 === 0 ? 'odd' : 'even',
                ]"
                color="primary"
                two-line
                @click="openLeadInfoSidebar(item.$id)"
              >
                <!-- <template #prepend>
                  <AppQrPopover
                    :copy-text="item.phone"
                    :link="`tel:${item.phone}`"
                    title="Anrufen"
                    :button-props="{
                      color: 'success',
                      icon: true,
                      size: 'small',
                      variant: 'text',
                    }"
                    :menu-props="{ openOnHover: true, location: 'top' }"
                    :size="120"
                    @click.stop
                  >
                    <Icon
                      name="tabler:phone"
                      size="24"
                    />
                  </AppQrPopover>
                </template> -->
                <!-- <template #append>

                </template> -->
                <div class="d-flex align-center w-100 ga-2">
                  <AppQrPopover
                    :copy-text="item.phone"
                    :link="`tel:${item.phone}`"
                    title="Anrufen"
                    :button-props="{
                      color: 'success',
                      icon: true,
                      size: 'small',
                      variant: 'text',
                    }"
                    :menu-props="{ openOnHover: true, location: 'top' }"
                    :size="120"
                    @click.stop
                  >
                    <Icon
                      name="tabler:phone"
                      size="24"
                    />
                  </AppQrPopover>
                  <div
                    class="d-inline-block text-truncate px-2 flex-shrink-1 flex-grow-0"
                    :class="{
                      'text-decoration-line-through text-disabled': item.status === 'completed',
                    }" @click="openLeadInfoSidebar(item.$id)"
                  >
                    {{ item.name }}<br>
                    {{ item.type }}
                  </div>
                  <div
                    class="ml-auto flex-shrink-0 flex-grow-0"
                  >
                    <v-btn
                      color="error"
                      icon
                      size="x-small"
                      variant="text"
                      @click.stop="updateNotes(item.$id, {
                        $id: undefined,
                        createdAt: undefined,
                        text: 'Lead nicht erreicht',
                        type: 'callAttempt',
                      })"
                    >
                      <Icon
                        name="tabler:thumb-down"
                        size="18"
                      />
                    </v-btn>
                    <v-btn
                      color="success"
                      icon
                      size="x-small"
                      variant="text"
                      @click.stop="updateNotes(item.$id, {
                        $id: undefined,
                        createdAt: undefined,
                        text: 'Lead erreicht',
                        type: 'callAttempt',
                      })"
                    >
                      <Icon
                        name="tabler:thumb-up"
                        size="18"
                      />
                    </v-btn>
                  </div>
                </div>
              </v-list-item>
            </template>
          </v-list>
        </v-fade-transition>
      </div>
    </v-hover>
  </v-card>
</template>

<style lang="scss" scoped>
.dialog-bottom {
  z-index: 3000;
  position: fixed;
}
:deep() {
  .v-list-item__content {
    overflow: hidden !important;
  }
}
.v-list-item {
  &.odd {
    background-color: rgba(var(--v-theme-surface-light), 0.1);
  }

  &.even {
    background-color: rgba(var(--v-theme-surface-bride), 0.1);
  }
  &:hover {
    background: rgba(var(--v-theme-surface), var(--v-theme-overlay-multiplier));
  }
}
</style>
