<script lang="ts" setup>
export interface Message {
  avatar?: string
  name?: string
  salution?: string | undefined
  subject?: string | undefined
  text: string
  to?: string
  type?: 'sms' | 'mail' | 'whatsapp' | 'call'
  user?: User
}

export interface User {
  avatar?: string
  mail: string
  name: string
  phone: string
}

const {
  activeTaskTab,
  color = 'background',
} = defineProps<{
  activeTaskTab: string
  color: string
}>()

// const messageItems = ref<HTMLDivElement | null>(null)

const rightSidebarStore = useRightSidebarStore()
const { isInfo, isOpen, isSchedule, title } = storeToRefs(rightSidebarStore)

// const messages = defineModel<any[]>('messages', { default: [] })
const lead = defineModel<any[]>('lead', { default: [] })

function toggleChat() {
  isOpen.value = !isOpen.value
}

const messageTypes = ref(
  [
    { icon: 'tabler:message-circle', title: 'SMS', type: 'sms' },
    { icon: 'tabler:mail', title: 'E-Mail', type: 'mail' },
    { icon: 'tabler:brand-whatsapp', title: 'WhatsApp', type: 'whatsapp' },
    { icon: 'tabler:phone', title: 'Anruf', type: 'call' },
  ],
)
// const messageType = ref<'sms' | 'mail' | 'whatsapp' | 'call'>('mail')

const isUpdatingPreview = ref(false)

const message = ref<Message>({
  text: '',
})

onMounted(() => {
  message.value = {
    avatar: lead.value.avatar,
    name: `${lead.value.firstName} ${lead.value.lastName}`,
    salution: `Guten Tag ${lead.value.firstName} ${lead.value.lastName},`,
    subject: undefined,
    text: '',
    to: lead.value.email,
    type: 'mail',
    user: {
      avatar: 'https://backend.broker.lion.cdsrv.de/uploads/605cc0f620b95d000fa40cc7_Adobe_Stock_364211147_c3aabf645d_073ee0472f.jpg',
      mail: 'sb@check-direkt.de',
      name: 'Max Mustermann',
      phone: '+49123456789',
    },
  }
})

const preview = ref('')
const isPreview = computed(() => {
  return message.value?.text && message.value?.type !== 'call'
},
)

// Debounce-Funktion, um die Vorschau zu aktualisieren
const updatePreview = useDebounceFn(async () => {
  isUpdatingPreview.value = true
  console.log(message.value)

  const response = await $fetch(
    `/api/v1/render/template`,
    {
      body: message.value,
      // headers: {
      //   cui: currentUser.$id,
      // },
      method: 'POST',
    },
  )

  preview.value = response as string
  isUpdatingPreview.value = false
}, 1000)

// Beobachten der Benutzereingaben und Aktualisieren der Vorschau
watch(() => ({
  salution: message.value.salution,
  text: message.value.text,
  type: message.value.type,
}), (value, oldValue) => {
  if (value.type !== oldValue.type && value.text !== '' && value.type !== 'call') {
    isUpdatingPreview.value = true
    preview.value = `<span />`
    updatePreview()
  }
  if (value.text !== '' && value.type !== 'call')
    updatePreview()
})
// watch(messageType, () => {
//   if (message.value.text !== '' && messageType.value !== 'call') {
//     isUpdatingPreview.value = true
//     preview.value = '<span></span>'
//     updatePreview()
//   }
// })
watch(lead, () => {
  message.value.text = ''
  message.value.avatar = lead.value.avatar
  message.value.name = `${lead.value.firstName} ${lead.value.lastName}`
  message.value.salution = `Guten Tag ${lead.value.firstName} ${lead.value.lastName},`
  message.value.subject = undefined
  message.value.to = lead.value.email
  preview.value = ''
})

// function sendMessage() {
//   messages.value = [{ createdAt: new Date(), text: message.value.text }, ...messages.value]
//   message.value.text = ''

//   setTimeout(() => {
//     messages.value = [
//       { createdAt: new Date(), inbound: true, text: `Danke für Ihre Nachricht ${messages.value.length + 1}`,
//       },
//       ...messages.value,
//     ]
//   }, 2000)
// }

// const messageTextInput = ref<HTMLElement | null | any>()

// const isFocused = ref(false)
// function handleFocus(focused: boolean) {
//   isFocused.value = focused
// }
</script>

<template>
  <v-navigation-drawer
    v-model="isOpen"
    class="multi-drawer elevation-0"
    :color
    disable-route-watcher
    permanent
    floating
    :width="$vuetify.display.width / 5"
    location="right"
    :scrim="false"
    style="z-index: 1005;"
  >
    <template #prepend>
      <v-toolbar class="d-flex align-center justify-space-between" color="surface-light" density="compact">
        <v-toolbar-title v-if="!isInfo" class="text-subtitle-2">
          {{ title }}
        </v-toolbar-title>
        <template #append>
          <v-btn aria-label="Close Chat" class="" size="40" variant="text" @click="toggleChat">
            <Icon name="tabler:x" size="18" />
          </v-btn>
        </template>
      </v-toolbar>
    </template>

    <v-fade-transition leave-absolute hide-on-leave>
      <!-- <v-card
        v-if="!isSchedule"
        class="pa-4 pr-2 d-flex flex-column h-100 elevation-0 rounded-s-xl" rounded="0" color="surface"
        variant="flat"
      >
        <div
          v-if="messages.length > 0 && lead" ref="messageItems"
          class="d-flex ga-4 flex-column-reverse justify-start overflow-y-auto flex-grow-1"
        >
          <v-slide-y-reverse-transition group>
            <div
              v-for="item in messages" :key="item.createdAt" class="d-flex" :class="{ 'align-self-end': !item.inbound }
              "
            >
              <v-avatar
                class="mx-2 mr-4" :class="{
                  'order-first': item.inbound,
                  'order-last': !item.inbound,
                }" color="primary" size="40"
              >
                <v-img
                  v-if="item.inbound && lead.avatarId"
                  alt="Avatar"
                  :src="`${useAppwrite().imagePreview('leadAvatars', lead.avatarId)}?width=40&height=40&output=webp`"
                />
                <v-img
                  v-else-if="!item.inbound" alt="Avatar"
                  src="https://backend.broker.lion.cdsrv.de/uploads/605cc0f620b95d000fa40cc7_Adobe_Stock_364211147_c3aabf645d_073ee0472f.jpg"
                />
                <span v-else>{{ useString().getInitials(`${lead.firstName} ${lead.lastName}`) }}</span>
              </v-avatar>
              <div>
                <v-card class="pa-2" variant="tonal">
                  {{ item.text }}
                </v-card>
                <div class="d-flex mx-2">
                  <small
                    :class="{
                      'ml-auto': !item.inbound,
                    }"
                  >
                    {{ useDateFns().untilNow(item.createdAt) }}</small>
                </div>
              </div>
            </div>
          </v-slide-y-reverse-transition>
        </div>

        <div v-else class="mx-8 d-flex ga-4 flex-column align-center justify-center h-100">
          <Icon name="material-symbols:android-chat" size="56" />
          <span v-if="!lead" class="text-center">
            Wähle einen Kontakt aus, um ein Gespräch zu beginnen.
          </span>
          <span v-else-if="messages.length === 0" class="text-center">
            Beginne ein Gespräch, indem Du unten Deine Nachricht eingibst.
          </span>
        </div>

        <TemplatePreview
          v-if="isPreview"
          :key="`${message.type}-${message?.text}`"
          v-model="preview"
          :loading="isUpdatingPreview"
          :type="message.type as ('sms' | 'mail' | 'whatsapp' | 'call')"
        />

        <div class="mt-4 flex-shrink-0 d-flex flex-column ga-2" variant="tonal">
          <v-fade-transition>
            <div
              v-if="message.type === 'mail' && message.text.length > 0"
              class="elevation-0 d-flex flex-column ga-1"
              color="surface"
              dense
            >
              <v-text-field
                v-model="message.subject"
                bg-color="white"
                density="compact"
                :disabled="!lead?.$id"
                placeholder="Betreff"
                rounded="xl"
              />
              <v-text-field
                v-model="message.salution"
                bg-color="white"
                density="compact"
                :disabled="!lead?.$id"
                placeholder="Anrede"
                rounded="xl"
              />
            </div>
          </v-fade-transition>
          <v-textarea
            ref="messageTextInput"
            v-model="message.text"
            auto-grow
            bg-color="white"
            placeholder="Schreibe Deinen Text"
            rounded="xl"
            :rows="isFocused || message.text.length > 0 ? 2 : 1"
            max-rows="5"
            @update:focused="handleFocus"
          >
            <template #prepend-inner>
              <v-menu content-class="elevation-0" offset-y offset="0">
                <template #activator="{ isActive, props: menuProps }">
                  <v-btn icon size="small" variant="text" v-bind="menuProps">
                    <Icon :name="(messageTypes.find(({ type }) => type === message.type)?.icon as string)" size="24" />
                    <v-tooltip
                      v-if="!isActive" activator="parent"
                      content-class="bg-background elevation-0 rounded-s-xl text-body-1" location="bottom"
                      text="wähle Deinen Nachrichten Typ aus"
                    />
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="type in messageTypes" :key="type.type" :title="type.title"
                    @click="message.type = type.type as ('sms' | 'mail' | 'whatsapp' | 'call')"
                  >
                    <template #prepend>
                      <Icon class="mr-4" :name="type.icon" size="24" />
                    </template>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template #append-inner>
              <v-btn color="success" :disabled="message.text.length === 0" icon size="small" variant="text" @click="sendMessage">
                <Icon name="tabler:send-2" size="24" />
              </v-btn>
            </template>
          </v-textarea>
        </div>
      </v-card> -->

      <!-- v-else -->
      <v-card
        class="pa-4 pr-2 d-flex flex-column h-100 elevation-0 rounded-s-xl" rounded="0" color="surface"
        variant="flat"
      >
        <v-fade-transition leave-absolute>
          <SidebarOpenCallItems v-if="activeTaskTab === 'openCalls'" />

          <v-card
            v-if="activeTaskTab === 'tasks'"
            class="d-flex flex-column text-caption overflow-y-auto flex-grow-1" rounded="0" variant="flat"
          >
            <div>
              <div
                class="py-0 pr-4 mb-2 text-body-2 font-weight-light d-flex align-center justify-space-between bg-surface"
                style="position:sticky; top:0; z-index: 1000; height:32px;"
              >
                <span>Erinnerungen / Termine</span>
                <!-- <span v-if="useLeadStore().openLeads.length > 0">{{ useLeadStore().openLeads.length }}</span> -->
              </div>
              <SidebarTaskList />
            </div>
          </v-card>

          <v-card
            v-if="activeTaskTab === 'messages'"
            class="d-flex flex-column text-caption overflow-y-auto flex-grow-1" rounded="0" variant="flat"
          >
            <div>
              <div
                class="py-0 pr-4 mb-2 text-body-2 font-weight-light d-flex align-center justify-space-between bg-surface"
                style="position:sticky; top:0; z-index: 1000; height:32px;"
              >
                <span>ungelesene Nachrichten

                </span>
                <!-- <span v-if="useLeadStore().openLeads.length > 0">{{ useLeadStore().openLeads.length }}</span> -->
              </div>
            </div>
          </v-card>

          <v-card
            v-if="activeTaskTab === 'openOffers'"
            class="d-flex flex-column text-caption overflow-y-auto flex-grow-1" rounded="0" variant="flat"
          >
            <div>
              <div
                class="py-0 pr-4 mb-2 text-body-2 font-weight-light d-flex align-center justify-space-between bg-surface"
                style="position:sticky; top:0; z-index: 1000; height:32px;"
              >
                <span>offene Angebote</span>
                <!-- <span v-if="useLeadStore().openLeads.length > 0">{{ useLeadStore().openLeads.length }}</span> -->
              </div>
              <!-- <v-list

            v-auto-animate
            class="py-0 flex-grow-1 flex-shrink-1 overflow-y-auto"
            density="compact"
          >
            <v-list-item
              v-if="useLeadStore().openLeads.length === 0"
              class="pa-1 px-3 mb-2 text-caption rounded-xl text-disabled"
              color="primary"
            >
              <div class="d-flex align-center">
                <Icon class="mr-2" name="tabler:checks" size="32" />
                <div class="d-block text-truncate text-body-2 font-weight-light">
                  Keine offenen Angebote
                </div>
              </div>
            </v-list-item>
            <v-list-item
              v-for="(item) in useLeadStore().openLeads"
              :key="item.$id"
              :active="false"
              :value="item"
              class="pa-1 px-3 mb-2 text-caption rounded-xl"
              color="primary"
              two-line
              @click="openLeadInfoSidebar(item.$id)"
            >
              <div class="d-block text-truncate">
                {{ item.firstName }} {{ item.lastName }}, {{ item.city }}
              </div>
              <div
                class="d-block text-truncate"
              >
                {{ item.type }} {{ item.status }}
              </div>
            </v-list-item>
          </v-list> -->
            </div>
          </v-card>
        </v-fade-transition>
      </v-card>
    </v-fade-transition>
  </v-navigation-drawer>
</template>

<style lang="scss" scoped>
.v-navigation-drawer {
  z-index: 2300 !important;
  max-height: calc(100dvh - 104px) !important;
}
.multi-drawer {
  transition-property: background-color, box-shadow, transform, visibility, y,
    width, height, left, right, top, bottom, margin-right;
}
</style>
