<script lang="ts" setup>
import {
  renderSVG,
} from 'uqr'

const hover = ref(false)
const advisorNav = ref([
  {
    icon: 'tabler:phone',
    text: 'anrufen',
    value: 'tel:+4916093072858',
  },
  {
    icon: 'tabler:brand-whatsapp',
    text: 'Whatsapp',
    value: 'https://wa.me/4916093072858',
  },
  {
    icon: 'tabler:mail',
    text: 'Mail schreiben',
    value: 'mailto:c.rabe@fondsfinanz.de',
  },
])

const myHoverableElement = ref()
const isHovered = useElementHover(myHoverableElement)

const { start: hideInfoBox } = useTimeoutFn(() => {
  hover.value = false
}, 250)

watch(isHovered, (value) => {
  if (!value)
    hideInfoBox()
})

const info = ref()
const src = ref()

function showInfoBox(type: string) {
  if (type === 'anrufen') {
    info.value = 'tel:+4916093072858'
    src.value = renderSVG(info.value)
  }
  if (type === 'Whatsapp') {
    info.value = 'https://wa.me/4916093072858'
    src.value = renderSVG(info.value)
  }
  if (type === 'Mail schreiben') {
    info.value = 'mailto:c.rabe@fondsfinanz.de'
    src.value = renderSVG(info.value)
  }
  hover.value = true
}
</script>

<template>
  <v-card
    ref="myHoverableElement"
    class="pa-3 overflow-visible mx-n2"
    color="transparent"
    density="compact"
    rounded="lg"
    variant="flat"
  >
    <div class="fill-height d-flex flex-no-wrap align-center justify-space-between">
      <div class="mx-2 align-center justify-space-between">
        <v-card-subtitle class="pa-0 text-caption font-weight-light">
          Lead-Vertrieb
        </v-card-subtitle>
        <v-card-title class="pa-0 text-body-1 font-weight-light">
          Christoph Rabe
        </v-card-title>

        <v-card-actions class="pa-0" style="min-height:32px">
          <v-btn
            v-for="item in advisorNav" :key="item.text" :aria-label="item.text" icon size="x-small" variant="text"
            external
            :href="item.value"
            target="_blank"
            @mouseenter="showInfoBox(item.text)"
          >
            <Icon :name="item.icon" size="18" />
            <v-tooltip
              activator="parent" content-class="elevation-0 rounded-xl text-body-1" location="top"
              :text="item.text"
            />
          </v-btn>
        </v-card-actions>
      </div>

      <v-avatar class="overflow-visible rounded-lg" size="86" rounded="0">
        <v-card class="card rounded-lg" :class="{ hover }">
          <div class="rounded-lg image">
            <img
              :src="`${useAppwrite().imagePreview('app', '660888a6001e08d6e2bd')}?width=86&height=86&output=webp`"
            >
          </div>

          <div class="rounded-lg bg-white details h-100 w-100 pa-1 d-flex flex-column overflow-hidden">
            <div
              v-show="hover"
              class="image-render-pixel"
              v-html="src"
            />
          </div>
        </v-card>
      </v-avatar>
    </div>
  </v-card>
</template>

<style lang="scss" scoped>
.image-render-pixel {
  image-rendering: pixelated;
}

.card {
  z-index: 100000;
  // box-shadow:
  //   rgba(var(--v-theme-surface-light), 0.4) -2px 0px 4px,
  //   rgba(var(--v-theme-surface-light), 0.3) -7px 0px 13px -3px,
  //   rgba(var(--v-theme-surface-light), 0.2) 3px 0px 0px inset !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 86px;
  height: 86px;
  overflow: visible;
}

.card .image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.card .image img {
  width: 100%;
  transition: 0.25s;
}

.card.hover .image img {
  opacity: 0.5;
  transform: translateX(100%);
  /*100%*/
}

.card .details {
  flex: 1 1 auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /*100%*/
  height: 100%;
  // background: #fff;
  transition: 0.25s;
  transform-origin: left;
  transform: perspective(2000px) rotateY(-90deg);
}

.card.hover .details {
  transform: perspective(2000px) rotateY(0deg);
}
</style>
