<script lang="ts" setup>
const isLeft = usePageLeave()

function compareVersions(currentVersion: string, appVersion: string): boolean {
  const splitAndConvert = (version: string): number[] => version.split('.').map(Number)
  const currentParts: number[] = splitAndConvert(currentVersion)
  const appParts: number[] = splitAndConvert(appVersion)

  const length: number = Math.max(currentParts.length, appParts.length)

  for (let i = 0; i < length; i++) {
    const currentPart: number = currentParts[i] ?? 0
    const appPart: number = appParts[i] ?? 0
    if (currentPart > appPart)
      return true
    if (currentPart < appPart)
      return false
  }

  return false
}

const currentVersion = ref<string>('')
async function checkVersion() {
  currentVersion.value = await $fetch('/api/v1/version', {
    baseURL: useRuntimeConfig().public.base.url,
    method: 'GET',
  })
}

const isNewerVersion = computed(() => compareVersions(currentVersion.value, useRuntimeConfig().public.version))

watch(isLeft, (value) => {
  if (value === false)
    checkVersion()
}, { immediate: true })

onMounted(() => {
  useAppSettingsStore().loadSettings()
})
</script>

<template>
  <v-app class="h-screen bg-gradiant">
    <UiLeftSidebar />
    <UiMainHeader />
    <UiRightSidebar />
    <v-main class="overflow-y-auto" scrollable>
      <!-- <v-container class="py-0 pa-4 fill-height"> -->
      <slot />
    </v-main>
    <v-snackbar
      v-model="isNewerVersion"
      color="accent"
      multi-line
      rounded="xl"
    >
      <template #actions>
        <a class="mx-2" :href="$route.fullPath">
          <!-- <v-btn icon variant="text" @click="$route.fullPath"> -->
          <Icon name="tabler:refresh" size="32" />
          <!-- </v-btn> -->
        </a>
      </template>
      Eine neue Version ist verfügbar, um den vollen Funktionsumfang zu nutzen, aktualisiere bitte die Seite.
    </v-snackbar>
  </v-app>
</template>
